:root {
  --theme-primary: #4fa9fb;
  --theme-secondary: #a3a3a3;
  --theme-primary-hover: #359dff;
  --theme-secondary-hover: #8c8c8c;
  --theme-grey: #e5e5e5;
}

.fileSizeInfo{
  font-size: 0.8rem;
  color: gray;
  display: block;
  margin-left: auto;
  width: fit-content;
}

@font-face {
  font-family: 'RobotoSlabBold';
  src: local('RobotoSlabBold'), url(./assets/fonts/RobotoSlab-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoSlabRegular';
  src: local('RobotoSlabRegular'), url(./assets/fonts/RobotoSlab-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RalewayRegular';
  src: local('RalewayRegular'), url(./assets/fonts/Raleway/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RalewaySemiBold';
  src: local('RalewaySemiBold'), url(./assets/fonts/Raleway/Raleway-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'RalewayBold';
  src: local('RalewayBold'), url(./assets/fonts/Raleway/Raleway-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter500';
  src: local('Inter500'), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto400';
  src: local('Roboto400'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

html {
  background: #d5d5d5;
  background-color: #d5d5d5;
}

* {
  font-family: RalewaySemiBold, serif !important;
}

/* ------------------------------------------------------------- */
/* WebKit Scrollbar */
.scrollElement::-webkit-scrollbar {
  /* width: 12px; Set the width of the scrollbar */
  min-width: 7px;
  min-height: 7px;
  width: 1vw;
  height: 1vw;
}

.scrollElement::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 1vw;
  min-height: 100px;
}

.scrollElement::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

/* Standard Scrollbar (Firefox, Edge, etc.) */
.scrollElement {
  scrollbar-width: thin;
  scrollbar-color: #888 #eee; /* Set the color of the scrollbar thumb and track */
}

.scrollElement:hover {
  scrollbar-color: #555 #eee; /* Set the color of the scrollbar thumb and track on hover */
}

/* -------------------------------------------------------------------- */

.pdf.scrollElement{
  display: flex;
}

.react-pdf__Document{
  margin-left: auto;
  margin-right: auto;
}

.theme-button-primary {
  background-color: var(--theme-primary) !important;
  color: #fff !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.theme-button-primary:hover {
  background-color: var(--theme-primary-hover) !important;
}

.theme-button-secondary {
  background-color: var(--theme-secondary) !important;
  color: #fff !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.theme-button-secondary:hover {
  background-color: var(--theme-secondary-hover) !important;
}

.themeButtonColor {
  background-color: var(--theme-primary) !important;
  /* align-items: center !important;
  justify-content: center !important; */
  color: #fff !important;
}

.themeButtonColor:hover {
  background-color: var(--theme-primary-hover) !important;
  color: #fff !important;
}

.themeButtonColor:focus {
  color: #fff !important;
  /* background-color: #0069d9; */
  border-color: #0062cc !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) !important;
}

.done-btn-text {
  margin: 0 1rem;
}

@media only screen and (max-width: 600px) {
  .done-btn-text {
    margin: 0;
  }
  .theme-button-primary,
  .theme-button-secondary {
    font-size: 0.8rem !important;
  }
}

.main-container {
  width: 100%;
  height: 100vh;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.navbar {
  background-color: #4fa9fb;
  color: #fff;
  width: 100%;
  height: 60px;
  padding: 10px;
  position: fixed;
  z-index: 10;
}

.remove-link-class {
  color: inherit;
  text-decoration: none;
}

.remove-link-class:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.formButtonText {
  /* font-family: RobotoSlabRegular, serif; */
  line-height: 25px;
  font-size: 15px;
  color: white;
  /* min-width:inherit */
}

.formControl {
  margin: 0px !important;
  padding: 0px !important;
}

.fieldSize {
  margin-bottom: 12px !important;
}

.errorfield {
  font-size: 0.7rem;
  height: 0.7rem;
  color: red;
}

.signInCardContainer {
  max-width: 400px;
}

.signUpCardContainerOuter {
  height: 485px;
}

.formButton {
  text-transform: none !important;
  background-color: #4fa9fb !important;
}

.formButton:disabled {
  text-transform: none !important;
  background-color: #9e9e9e !important;
}

@media only screen and (max-width: 500px) {
  .formButtonText {
    line-height: 25px;
    font-size: 12px;
  }

  .formControl input {
    font-size: 12px !important;
  }
}

.logo {
  width: 40px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 100%;
  height: auto;
}

.navbar .title {
  font-size: 1.2rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: auto;
}

.navbar .expiry {
  font-size: 0.8rem;
}

@media only screen and (max-width: 500px) {
  .navbar .title {
    font-size: 0.8rem;
  }

  .navbar .expiry {
    font-size: 0.5rem;
  }
}

/* bootstrap compatible breakpoint media queries */
/* // extra Small devices (phones, 576px and up) */
@media only screen and (max-width: 575.98px) {
  .userImage {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 35%;
  }

  .signUpCardContainer {
    min-width: 266px;
    max-width: 90%;
    height: 100%;
    width: -webkit-fill-available;
  }

  .signInCardContainerOuter {
    height: 302px;
  }

  .signInCardContainer {
    margin-top: 30px;
    min-width: min-content;
    height: 100%;
    max-width: 100%;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .userImage {
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .signUpCardContainer {
    min-width: min-content;
    max-width: 90%;
    height: 100%;
    width: -webkit-fill-available;
  }

  .signInCardContainer {
    min-width: min-content;
    height: 100%;
    width: -webkit-fill-available;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  @media screen and (min-height: 768px) {
    .userImage {
      width: 30%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .signUpCardContainer {
      margin-top: 30px;
      min-width: min-content;
      height: 100%;
      width: 50%;
    }

    .signInCardContainer {
      margin-top: 30px;
      min-width: min-content;
      height: 100%;
      width: 50%;
    }
  }

  @media screen and (max-height: 767px) {
    .userImage {
      width: 14%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .signUpCardContainer {
      margin-top: 30px;
      min-width: min-content;
      height: 100%;
      width: 50%;
    }

    .signInCardContainer {
      margin-top: 30px;
      min-width: min-content;
      height: 100%;
      width: 50%;
    }
  }
}

/* // Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .userImage {
    width: 55%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .userImage {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/* color scheme for dark mode */
/*@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
  }
}*/
/* color scheme for light mode */
/*@media (prefers-color-scheme: light) {
  body {
    background-color: green;
  }
}*/

.document-header-loader {
  width: 100%;
  height: 57px;
  padding: 10px;
  position: fixed;
  z-index: 10;
  margin-top: 60px;
  text-align: center;
}

.loader {
  width: 100%;
  height: auto;
  padding: 10px;
  text-align: center;
}

.document-header {
  background-color: var(--theme-grey);
  width: 100%;
  /* height: auto; */
  height: 60px;
  padding: 10px;
  position: fixed;
  z-index: 10;
  margin-top: 60px;
}

.document-header .pagination img {
  width: 1.5rem;
  height: auto;
}

.document-header .pagination span {
  font-size: 1.2rem;
  color: #727272;
  text-wrap: nowrap;
}

.document-header .dropdown-helper-text {
  font-size: 0.9rem;
  color: var(--theme-primary);
}

@media only screen and (max-width: 600px) {
  .document-header .pagination span {
    font-size: 1rem;
  }

  .document-header .pagination img {
    width: 1.25rem;
    height: auto;
  }

  .document-header .row .col:nth-child(2) {
    display: none !important;
  }

  .document-header .dropdown-helper-text {
    font-size: 0.8rem;
  }
}

.theme-text-primary {
  color: var(--theme-primary);
}

.theme-text-secondary {
  color: var(--theme-secondary);
}

.document-view {
  background-color: #a3a3a3;
  width: 100%;
  padding-top: 117px;
  /* height: calc(100vh - 80px); */
}

.viewbox {
  /* width: fit-content;
  max-width: 90%; */
  width: 100%;
  max-width: 100%;
  height: auto;
  /* backgroundColor must be same as html backgroundColor */
  background-color: #d5d5d5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.viewbox .pdf {
  width: 100%;
  padding: 10px;
  height: auto;
  overflow-y: auto;
  /* border: 4px solid #000; */
}

.viewbox .pdf .pdfPage {
  margin-bottom: 10px;
}

.viewbox .pdf .react-pdf__Page__canvas {
  /* width: 100% !important; */
  height: auto;
  margin: 0 auto !important;
}

.signature_modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* min-width: 300px;
  max-width: 500px;
  width: 90%;
  min-height: 380px;
  max-height: 430px;
  height: 60vh; */
  min-width: 600px;
  width: 56vw;
  height: 60vh;
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  display: block;
  outline: none;
}

.signature_modal .signature_modal-header {
  background-color: var(--theme-grey);
  overflow: hidden;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
  height: 90px;
}

.signature_modal .signature_modal-header button {
  padding-top: 20px;
}

.signature_modal .signature_modal-header .css-15dm1r7-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--theme-primary);
}

.signature_modal .signature_modal-header .css-1aquho2-MuiTabs-indicator {
  background-color: var(--theme-primary);
}

.signature_modal .signature_modal-sub-header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aquamarine; */
}

.stroke .css-53tbrc-MuiSlider-root {
  width: 50% !important;
}

.stroke .css-1623zc {
  width: 50% !important;
}

.signature_modal .signature_modal-body {
  position: relative;
  width: 100%;
  /* Height is calculted by subtracting (header, sub-header and footer) height */
  height: calc(100% - 240px);
  /* background-color: aquamarine; */
}

.signature_modal .signature_modal-footer {
  /* background-color: burlywood;
  opacity: 0.5; */
  width: 100%;
  height: 70px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.signature_modal-footer .sign-modal-action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit;
}

.signature_modal-footer .sign-modal-action button + button {
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
  .signature_modal {
    min-width: 90vw;
    width: 56vw;
    height: 60vh;
  }

  .stroke .css-53tbrc-MuiSlider-root {
    width: 75% !important;
  }

  .signature_modal .signature_modal-header button {
    font-size: 0.8rem !important;
  }
}

.centerline {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-self: center;
  height: 0px;
  width: 100%;
}

.signature-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature-area canvas{
  /* background: red; */
  border: 1px solid #d3d3d3 !important;
}

.signature-textfield {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.top {
  display: flex;
  justify-content: center;
  align-items: end;
  /* background-color: cadetblue; */
  height: 50%;
}

.bottom {
  height: 50%;
  /* background-color: cornflowerblue; */
}

.top input {
  width: 90%;
  background-color: transparent;
  font-size: 3rem;
  border: 0;
  text-align: center;
  color: #000;
}

.top input::placeholder {
  opacity: 1;
  color: #000;
}

.top input:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .top input {
    font-size: 1rem;
  }
}

.document-status div {
  color: #fff;
  font-size: 1rem;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 20px;
}

.document-status .status-completed {
  background-color: #046306;
}

.document-status .status-withdrawn {
  background-color: #a10202;
}

.document-status .status-rejected {
  background-color: #503c3c;
}

.document-status .status-expired {
  background-color: #000000;
}

.progress-modal .header-menu{
  display: flex;
  border: 1px solid;
  align-items: center;
}

.progress-modal .progress-area{
  display: flex;
  flex-direction: column;
  height: 100px;
}

.progress-modal .ProgressInfo{
  font-size: 0.8rem;
  font-family: 'RalewayRegular' !important;
}

.progress-modal hr{
  /* make line to take full width */
  width: calc(100% + 20px);
  margin-left: -10px;
}

.success-modal,
.progress-modal {
  padding: 20px 10px;
}

.success-modal div:nth-child(1)>img {
  width: 40%;
  height: auto;
}

.success-modal div:nth-last-child(1)>a>img {
  width: 100px;
  height: auto;
  border-radius: 30px;
}

.success-modal span {
  font-size: 1rem;
  text-align: center;
}

.success-modal div:nth-last-child(2)>span {
  font-family: 'RalewayRegular' !important;
}

.appAdvertisement{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  gap: 0.3rem;
}
.appAdvertisement span{
  font-family: 'RalewayBold' !important;
}

@media screen and (max-width: 600px) {
  .success-modal span {
    font-size: 0.8rem;
  }
}

.validation-modal-header {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}
.validation-modal-header img {
  width: auto;
  height: 100px;
}
.validation-modal-header h2 {
  font-size: 2rem;
  margin: 10px auto;
  color: #474747;
}

.validation-modal-header p {
  font-size: 1rem;
  margin: 10px auto;
  color: #474747;
  text-align: left;
}

@media screen and (max-width: 500px) {
  .validation-modal-header img {
    height: 70px;
  }
  .validation-modal-header h2 {
    font-size: 1.4rem;
  }

  .validation-modal-header p {
    font-size: 0.7rem;
  }
  small {
    font-size: 0.7rem;
  }
}

/* -------------------------------------------------------------------------------- */

.fieldsListItemStyle{
  width: 95% !important;
  margin-left: 5%;
}