.contactUsFormSection{
  min-width: 280px;
}


#contact-form .contactSubmitBtn {
  background: var(--themeBlueColor);
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

#contact-form .contactSubmitBtn:hover {
  opacity: 0.7;
}

#contact-form .contactSubmitBtn:disabled{
  background: #b5b5b5;
}

.spinner {
	border: 2px solid rgba(0,0,0,.1);
	border-top: 2px solid #ffff;
	border-radius: 50%;
	height: 1.3rem;
	width: 1.3rem;
	animation: spin 1s linear infinite;
	vertical-align: middle;
	display: none;
  margin-left: 10px;
}


.expertise-section .contactUsFormSection {
  display: block;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
}

.expertise-section .contactUsFormSection .formContainer > :last-child {
  justify-content: flex-start;
}

#contactusFormStatusDiv {
  background-color: transparent;
  border-radius: 3px;
  color: #000;
  height: 2.6rem;
  line-height: 2.6rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}

#contactusFormStatusDiv #statusInnerDiv {
  display: none;
}

.contactUsFormSection .formContainer {
  width: 100%;
  border-radius: 0.8125rem;
  z-index: 10;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.8rem 0 1rem 0;
}

.contactUsFormSection .formContainer .form-heading {
  margin-top: 0;
  /* margin-bottom: 2.3125rem; */
  margin-bottom: 1.5rem;
  line-height: 2.5rem;
}

.contactUsFormSection .formContainer .form-sub-heading {
  line-height: 1.25rem;
}

.contactUsFormSection .customInputGroup > div {
  width: 47.5%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.contactUsFormSection .customInputGroup:has(textarea) > div {
  width: 100%;
}

.contactUsFormSection .formContainer input.formInput,
.contactUsFormSection .formContainer textarea.formInput,
.contactUsFormSection .formContainer select.formInput {
  width: 100%;
  height: 2.5625rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #ebebeb;
}

.contactUsFormSection .formContainer input.formInput,
.contactUsFormSection .formContainer textarea.formInput,
.contactUsFormSection .formContainer select.formInput {
  padding: 0.375rem 0.9375rem;
}

.contactUsFormSection .formContainer select.formInput {
  padding: 0.375rem 0.75rem;
}

.contactUsFormSection .formContainer textarea.formInput {
  margin-right: 0;
  width: 100%;
  height: 5.875rem;
  resize: none;
}

.contactUsFormSection .formContainer .flex-row {
  width: fit-content;
  margin-bottom: 1rem;
}

.contactUsFormSection .formContainer .flex-row input.formInput:last-child {
  margin-right: 0;
}

.contactUsFormSection .formContainer .formSubmitBtn {
  width: 10.1875rem;
  height: 2.7rem;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  background: #ff6961;
}

.contactUsFormSection .formContainer .formSubmitBtn:hover {
  opacity: 0.8;
}

.customInputGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.twinSection {
  display: flex;
  flex-direction: column;
  align-items: end;
  flex-grow: 1;
}

.twinLeft {
  width: 100%;
  height: 300px;
}

.twinRight {
  width: 100%;
  margin-top: 30px;
  height: 300px;
}

.formSection {
  margin: 0px 10%;
}

@media only screen and (min-width: 900px) {
  .twinSection {
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  .twinLeft {
    width: 49%;
    height: calc(26vw - 20px);
  }

  .twinRight {
    width: 49%;
    height: calc(26vw - 20px);
    margin-top: 0px;
  }
}

.contact-description {
  padding-bottom: 0.5rem;
  max-width: 44rem;
  margin-left: auto;
  margin-right: auto;
}

.contactUsFormSection .formContainer input.formInput.error,
.contactUsFormSection .formContainer textarea.formInput.error,
.contactUsFormSection .formContainer select.formInput.error {
  border: 0.0625rem solid #ff6961;
}

.contactUsFormSection .formContainer input.formInput + p,
.contactUsFormSection .formContainer textarea.formInput + p,
.contactUsFormSection .formContainer select.formInput + p {
  display: none;
}

.contactUsFormSection .formContainer input.formInput.error + p,
.contactUsFormSection .formContainer textarea.formInput.error + p,
.contactUsFormSection .formContainer select.formInput.error + p {
  display: block;
  color: #ff6961;
}

@media only screen and (max-width: 900px) {
  .contactUsFormSection {
    flex-direction: column;
    margin-bottom: unset;
  }

  .intro-section .formContainer {
    width: calc(50vw + 100px);
    height: auto;
    margin: auto;
    min-width: 280px;
    padding: 0px !important;
  }

  .customInputGroup {
    flex-direction: column;
  }

  .customInputGroup input,
  .customInputGroup select {
    width: 100%;
  }

  .contactUsFormSection .customInputGroup > div {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .contactUsFormSection .formContainer {
    padding: 0.7rem 0 2.4rem 0;
  }
}

@media only screen and (min-width: 576.01px) and (max-width: 900px) {
  .contactUsFormSection .formContainer {
    padding: 0.7rem 0 2.4rem 0;
  }
}
