/* ------------------------------------ VIEW DOCUMENT MOBILE ------------------------------------------ */

.btn-theme-default{
  background-color: #4fa9fb;
  line-height: 1;
  box-sizing: border-box;
  color: #ffffff;
}

.btn-theme-default:hover{
  opacity: 0.8;
  color: #ffffff;
}

.btn.theme-button-attachment{
  width: 96px;
  padding: 4px 6px;
  margin-right: 0px;
  line-height: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.fontDiv{
  font-size: 15px;
  margin-bottom: 6px;
}

.fontDiv>div>span{
  margin-right: 10px;
}

.fontDiv .btn{
  background-color: #c9c9c9;
  border-color: transparent;
  color: black;
  padding: 0px 6px;
  font-size: 30px;
  font-weight: bolder;
  line-height: 0.7;
}
.fontDiv .btn:last-child{
  margin-left: 1px;
}

.fontDiv .btn:hover{
  opacity: 0.8;
}

.menuButtonsSetting{
  display: flex;
}

.navbarInner > div:nth-child(1),
.navbarInner > div:nth-child(3)  {
  width: 200px;
}

.navbarTitleDiv{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2rem;
}

.centerAbsoluteHorizontally{
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.customBadge{
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff !important;
  background-color: #4fa9fb;
  text-transform: none !important;
  padding: 3px 5px;
  margin: 0 6px;
  line-height: 1;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.7rem;
}


/* NAVBAR */
.nav-header {
  background-color: #4fa9fb;
  color: #fff;
  width: 100%;
  height: 47px;
  position: fixed;
  z-index: 1250;
  min-width: 280px;
}

.nav-header .title {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0em;
}

.nav-header .help {
  font-size: 14px;
  line-height: 16px;
}

/* DOCUMENT HEAD */
.document-header-sm {
  background-color: var(--theme-grey) !important;
  margin-top: 47px;
  height: 65px !important;
  color: #363636;
  width: 100%;
  position: fixed;
  min-width: 280px;
}

.document-header-sm .required-text {
  font-family: 'Inter500' !important;
  font-size: 1rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: auto;
  padding: 0 20px;
}

.document-header-sm .expiry {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-wrap: wrap;
}

.header-button {
  width: 86px;
  height: 33px;
  border-radius: 60px;
  background: #4fa9fb;
  box-shadow: -3px 4px 7px 0px #3aacff63;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.header-button:hover{
  opacity: 0.8;
}

.header-button:disabled{
  background: #a3a3a3;
  cursor: auto;
}

/* DRAWER MENU */
.MuiListItemText-primary,
.list-item-text {
  font-family: Roboto400 !important;
  line-height: 1 !important;
  letter-spacing: 0em !important;
  color: rgba(81, 81, 81, 1);
  text-transform: capitalize;
}

.status-text {
  line-height: 19px;
  color: rgba(81, 81, 81, 1);
  margin-bottom: 5px;
  font-size: 14px;
}

.drawer p {
  font-family: Roboto400 !important;
  color: rgb(193, 193, 193);
  margin: 12px 0 0 16px;
  font-size: 14px;
}

.clear-button {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(79, 169, 251, 1);
  background-color: transparent;
  border: none;
}

.theme-button-attachment{
  /* background-color: #008B00; */
  background-color: #838383;
  color: #fff !important;
  text-transform: none !important;
  /* font-size: 1rem !important; */
}
.theme-button-attachment:hover{
  opacity: 0.8;
}


@media (max-width: 1023.98px) {
  .navbar,
  .document-header,
  .signature_modal-header .MuiTabs-indicator,
  .clear-btn {
    display: none;
  }

  .logo img {
    height: 30px;
  }

  /* --------------------------------------- MODALS ---------------------------------------- */
  .signature_modal {
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .signature_modal-header {
    height: 56px !important;
  }

  .signature_modal .signature_modal-header button {
    padding-top: 0;
  }

  .signature_modal-header button .tab-text {
    display: none;
  }

  .signature_modal-header {
    background: rgba(232, 232, 232, 1);
    top: 100px;
    border-radius: 0 !important;
  }

  .sign-modal-action button {
    width: 136px;
    height: 45px;
    font-family: 'Roboto400' !important;
    font-size: 18px !important;
  }

  .close-btn {
    background-color: rgba(242, 242, 242, 1) !important;
    border: 1px solid rgba(0, 0, 0, 1);
    color: rgba(90, 90, 90, 1) !important;
  }

  /* --------------------------------------- PDF VIEW ---------------------------------------- */
  .document-view {
    padding-top: 112px !important;
  }

  /* .viewbox {
    max-width: 100%;
  } */

  .viewbox .pdf {
    height: calc(100vh - 112px);
  }

  .signature_modal .signature_modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .centerline-text {
    height: auto !important;
  }
}

@media (min-width: 1024px) {
  .document-head-mv,
  .drawer,
  .close-btn,
  .clear-button,
  .smallScreen {
    display: none !important;
  }
}

@media only screen and (min-width: 576px){
  .document-header-sm button:nth-child(1){
    margin-right: 50px;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1023.98px){
  .menuButtonsSetting {
    flex-direction: column;
    justify-content: end;
    margin-right: 10px;
  }

  .menuButtonsSetting>div{
    justify-content: end;
  }

  .menuButtonsSetting .itemBtn{
    padding: 0 4px;
    line-height: 1;
  }
} */
