/**
* Template Name: Bocor - v2.0.0
* Template URL: https://bootstrapmade.com/bocor-bootstrap-template-nice-animation/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root{
  --themeBlueColor: #72b8e3;
}
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: var(--themeBlueColor)
}

a:hover {
  color: var(--themeBlueColor);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

.vbox-container{
  overflow: hidden !important;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  right: 15px;
  bottom: 15px;
  background: #284864;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
  transition: all ease-in-out 0.3s;
}

.back-to-top i {
  font-size: 24px;
}

.back-to-top:hover {
  color: #fff;
  background: var(--themeBlueColor);
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#headerBar {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 16px 0;
  background: #213b52;
}

#headerBar .appLogo h1 {
  text-transform: none;
  font-size: 24px;
  margin: 0;
  padding: 0 0 0 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px;
  font-family: "Poppins", sans-serif;
}

#headerBar .appLogo h1 a, #headerBar .appLogo h1 a:hover {
  color: #fff;
  text-decoration: none;
  text-transform: none;
}

#headerBar .appLogo h1 a span, #headerBar .appLogo h1 a:hover span {
  color: var(--themeBlueColor);
}

#headerBar .appLogo img {
  padding: 0;
  margin: 0;
  max-height: 48px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu ul {
  border-radius: 6px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #adc7de;
  padding: 10px 15px;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #fff;
  text-decoration: none;
}

.nav-menu .get-started a {
  background: #213b52;
  color: #fff;
  border-radius: 50px;
  margin: 2px 0 0 15px;
  padding: 4px 25px 6px 25px;
  border: 2px solid var(--themeBlueColor);
}

.nav-menu .get-started a:hover {
  background: var(--themeBlueColor);
  color: #213b52;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #3e6f9b;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: var(--themeBlueColor);
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 25px;
  z-index: 9999;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle .icofont-close{
  background: #213b52;
}

.mobile-nav-toggle i {
  color: var(--themeBlueColor);;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #213b52;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
  transition: 0.3s;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: var(--themeBlueColor);
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav .get-started a {
  background: #213b52;
  color: #fff;
  border-radius: 50px;
  margin: 15px;
  padding: 8px 25px 10px 25px;
  text-align: center;
}

.mobile-nav .get-started a:hover {
  background: var(--themeBlueColor);
  color: #213b52;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(18, 33, 46, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  padding: 0 0 0 0;
  background: #213b52;
  background: linear-gradient(180deg, #213b52 0%, #213c54 35%, #204564 100%);
}

#hero h1 {
  margin: 0 0 15px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #d2e0ed;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 22px;
}

#hero .btn-get-started {
  color: #213b52;
  border-radius: 50px;
  padding: 8px 35px 10px 35px;
  border: 2px solid var(--themeBlueColor);
  transition: all ease-in-out 0.3s;
  display: inline-block;
  background: var(--themeBlueColor);
}

#hero .btn-get-started:hover {
  background: transparent;
  color: #fff;
}

.heroContent h2{
  text-align: justify;
}

@media (max-width: 991px) {
  .heroContent h1{
    text-align: center;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero {
    -moz-text-align-last: center;
    text-align-last: center;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background: linear-gradient(180deg, #f2f6fa 0%, #fff 100%);
}

.section-title {
  text-align: center;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #213b52;
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--themeBlueColor);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 0;
}

.clients .client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 120px;
}

.clients .client-logo img {
  height: 40px;
}

.clients .client-logo:hover img {
  transform: scale(1.2);
}

.clients img {
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
  padding: 30px 0;
}

.about .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #213b52;
}

.about .content p {
  margin-bottom: 0;
}

.about .content .icon-box {
  margin-top: 25px;
}

.about .content .icon-box img{
  position: absolute;
  width: 52px;
}

.about .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
  color: #213b52;
}

.about .content .icon-box h4 a {
  color: #213b52;
  transition: 0.3s;
}

.about .content .icon-box h4 a:hover {
  color: var(--themeBlueColor);
}

.about .content .icon-box i {
  font-size: 48px;
  float: left;
  color: var(--themeBlueColor);
}

.about .content .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.about .image {
  /* background: url("../img/about.jpg") center center no-repeat; */
  background: url("../indexPage/about.jpg") center center no-repeat;
  background-size: cover;
  min-height: 400px;
}

@media (max-width: 667px) {
  .about .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
  padding-bottom: 40px;
}

.services .card {
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
  background: transparent;
}

.services .card-img {
  width: calc(100% + 60px);
  margin-left: -30px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0;
}

.services .card-img img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.services .card-body {
  z-index: 10;
  background: #fff;
  border-top: 4px solid #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  transition: 0.3s;
}

.services .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.services .card-title a {
  color: #213b52;
  transition: 0.3s;
}

.services .card-text {
  color: #5e5e5e;
}

.services .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
}

.services .read-more a:hover {
  color: var(--themeBlueColor);
}

.services .card:hover img {
  transform: scale(1.1);
}

.services .card:hover .card-body {
  border-color: var(--themeBlueColor);
}

.services .card:hover .card-body .card-title a {
  color: var(--themeBlueColor);
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .content + .content {
  margin-top: 100px;
}

.features .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #213b52;
}

.features .content ul {
  list-style: none;
  padding: 0;
}

.features .content ul li {
  padding-bottom: 10px;
}

.features .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--themeBlueColor);
}

.features .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
  min-width: 280px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 0 35px 0;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 0 5px 5px 0;
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: var(--themeBlueColor);
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(33, 59, 82, 0.6);
  position: absolute;
  left: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
}

.portfolio .portfolio-wrap img {
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: var(--themeBlueColor);
  font-size: 24px;
  line-height: 1;
  text-align: center;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links i{
  width: 36px;
  height: 36px;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #fff;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: none;
}

.portfolio .portfolio-wrap:hover::before {
  left: 0;
}

.portfolio .portfolio-wrap:hover {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .portfolio-links{
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  top: 0;
}

.portfolio .portfolio-links a{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
}

.team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  min-height: 260px;
}

.team .member .pic img {
  max-width: 100%;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #213b52;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: #5289ba;
  transition: 0.3s;
}

.team .member .social a:hover {
  color: var(--themeBlueColor);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
  position: relative;
}

.pricing .featured-badge {
  display: inline-block;
  position: absolute;
  top: -11px;
  left: calc(50% - 49px);
  background: var(--themeBlueColor);
  color: #fff;
  text-align: center;
  line-height: 1;
  border-radius: 50px;
  padding: 4px 20px 5px 20px;
  font-size: 12px;
  text-transform: uppercase;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: var(--themeBlueColor);
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  display: flex;
  padding-bottom: 12px;
}

.pricing ul i {
  color: var(--themeBlueColor);
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .get-started-btn {
  background: #213b52;
  display: inline-block;
  padding: 6px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
  margin: auto auto 0 auto;
}

.pricing .get-started-btn:hover {
  background: var(--themeBlueColor);
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
  border: 1px solid var(--themeBlueColor);
}

.pricing .featured .get-started-btn {
  background: var(--themeBlueColor);
}

.pricing .featured .get-started-btn:hover {
  background: #fdcd5c;
}

@media (min-width: 992px) {
  .pricing .box {
    flex: 0 0 49% !important;
    max-width: 49% !important;
  }
}

@media (max-width: 992px) {
  .pricing .box {
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid #dde8f1;
}

.faq .faq-item i {
  color: #9bbbd7;
  font-size: 20px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}

.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 28px;
  font-family: "Poppins", sans-serif;
}

.faq .faq-item p {
  font-size: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
/* .contact{
  padding: 12px 0 60px 0;
} */

.contact img {
  max-width: 90%;
  align-self: center;
  margin-top: 10px;
}

.contact .leftSide{
  display: flex;
  flex-direction: column;
  align-self: center;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}

.contact .info-box i {
  font-size: 32px;
  color: var(--themeBlueColor);
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fff7e5;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus, .contact .php-email-form textarea::focus {
  background-color: var(--themeBlueColor);
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: var(--themeBlueColor);
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: var(--themeBlueColor);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: url("../indexPage/footer-bg.jpg") center center no-repeat;
  color: #fff;
  font-size: 14px;
  position: relative;
}

#footer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(26, 46, 64, 0.85);
  z-index: 1;
}

#footer .footer-top {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 80px 0 12px 0;
}

#footer .download-badges{
  display: flex;
  justify-content: center;
  list-style: none;
}

#footer .download-badges li a.badge-appstore {
  background-image: url(../indexPage/appstore.jpg);
  opacity: 1;
}

#footer .download-badges li a {
  display: block;
  width: 160px;
  height: 49px;
  font: 0 / 0 a;
  text-shadow: none;
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 160px 49px;
}

#footer .tap-target{
  padding: 2%;
}

#footer .copyright span:not(:last-child)::after {
  content: "|";
  display: inline-block;
  padding: 0 1rem 0 1.2rem;
  color: rgba(255, 255, 255, .7);
}

#footer .footer-top h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  margin-bottom: 0;
}

#footer .footer-top p {
  font-size: 15;
  font-style: italic;
  margin: 30px 0 0 0;
  padding: 0;
}

#footer .footer-top .footer-newsletter {
  text-align: center;
  font-size: 15px;
  margin-top: 30px;
}

#footer .footer-top .footer-newsletter form {
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--themeBlueColor);
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: var(--themeBlueColor);
}

#footer .footer-top .social-links {
  margin-top: 30px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #284864;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: var(--themeBlueColor);
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  border-top: 1px solid #1a2e40;
  z-index: 2;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

#footer .copyright {
  text-align: center;
  /* float: left; */
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
}

@media (max-width: 768px) {
  #footer .copyright, #footer .credits {
    padding: 5px;
    float: none;
    text-align: ceneter;
  }
}

@media (max-width: 992px) {
  #footer .copyright span{
    display: block;
    width: 100%;
  }

  #footer .copyright span:not(:last-child)::after{
    display:none;
  }
}
